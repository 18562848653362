
.img-lateral-profile {
    width: 65px;
}
.img-lateral-profile-hidden {
    width: 50px;
}
.logo-element-profile-hidden {
    padding: 5px;
}

.radios-block {
    display: block !important;
}

a.dim {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px !important; }

a.dim:active {
    top: 3px; }

a.btn-default.dim {
    box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999; }

a.btn-default.dim:active {
    box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999; }

a.btn-warning.dim {
    box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999; }

a.btn-warning.dim:active {
    box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999; }

a.btn-info.dim {
    box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999; }

a.btn-info.dim:active {
    box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999; }

a.btn-success.dim {
    box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999; }

a.btn-success.dim:active {
    box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999; }

a.btn-danger.dim {
    box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999; }

a.btn-danger.dim:active {
    box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999; }

a.dim:before {
    font-size: 50px;
    line-height: 1em;
    font-weight: normal;
    color: #fff;
    display: block;
    padding-top: 10px; }

a.dim:active:before {
    top: 7px;
    font-size: 50px; }




/***********************************************************/
/*                        ENTI                             */
/***********************************************************/
h1{font-size:25px; font-weight: 700; color:#706f6f;}
h2{font-size:20px; font-weight: 700; color:#706f6f;}
h3{font-size:18px; font-weight: 700; color:#706f6f;}
h4{font-size:14px; font-weight: 900; color:#706f6f;}
h5{font-size:12px; font-weight: 400; color:#706f6f;}
h6{font-size:10px; font-weight: 300; color:#706f6f;}
p{font-size:16px; font-weight: 300; color:#3c3c3b;}

td{font-size:16px; font-weight: 300; color:#3c3c3b;}
.autorize label{font-size:16px; font-weight: 300; color:#3c3c3b;}
#field_enrollment_type .control-label{font-size:18px; font-weight: 700; color:#706f6f;}

#file_pau, #digital_photo, #nif_file{
    background-color: transparent !important;
    background-image: none;
    border: 0px solid #e5e6e7 !important;
    border-radius: 0px;
    color: inherit;
    display: block;
    padding: 0px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
}

#file_pau::before{
    background-color: red !important;
}

#file_pau::before{
    background-color: red !important;
}

.progress-indicator {
    margin: 30px 0px 50px 0;
}

.progress-indicator > li .bubble {
    border-radius: 1000px;
    width: 30px;
    height: 30px;
}
.progress-indicator > li .bubble::after, .progress-indicator > li .bubble::before {
    top:13px;
}
.progress-indicator {
    font-size: 12px;
}

.navbar-fixed-top, .navbar-static-top{
    background: #fff;
}

.wrapper-content {
    padding: 20px 10px 70px;
}
fieldset{
    margin-top: 35px;
}
.ibox-content{
    padding: 20px 40px 20px 40px;
}
.table-autorize tr td {
    padding-bottom: 15px;
}

.table-autorize{
    margin-bottom:20px;
    margin-top:20px;
}

tbody th{
    font-size: 15px !important;
}

thead th{
    font-size: 16px  !important;
}

button.dim{
    width:auto;
}

.panel-body-button{
    float:left !important;
}

.total-alumnes .ibox-title{
    min-height:60px;
}
.total-alumnes .ibox-title h5{
    font-weight: 900 !important;
}
.btn-large {
    color: #706f6f ;
    background: white !important;
    border: 1px solid #e7eaec;
    font-size:22px;
}

.paddingPdf{
    padding-right: 10px;
}
.btn {
    margin-left: 5px;
}

.btn-login-as {
    background-color: #7F8C8D;
    border-color: #7F8C8D;
    color: #FFFFFF;
}

.btn-convalidations {
    background-color: #E26A6A;
    border-color: #E26A6A;
    color: #FFFFFF;
}

.btn-export {
    background-color: #7EBC59;/*008F68*/
    border-color: #7EBC59;
    color: #FFFFFF;
}

.btn-import {
    background-color: #368CBF;/*006899*/
    border-color: #368CBF;
    color: #FFFFFF;
}

.label-attachment {
    background-color: #854e4b;
    border-color: #854e4b;
    color: #FFFFFF;
    margin-right: 2px;
}

.btn-docs {
    background-color: #23c6c8;
    border-color: #23c6c8;
    color: #FFFFFF;
}

.label-not-revised {
    background-color: #ED5565;
    border: 1px solid #dd414b;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 2px;
}

.label-revised {
    background-color: #A1CB5B;
    border: 1px solid #8BAF4E;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 2px;
}

.label-comprovant {
    background-color: #23c6c8;
    border: 1px solid #23c6c8;
    color: #FFFFFF;
}

.label-unchecked {
    background-color: #CCCCCC;
    border: 1px solid #adadad;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 2px;
}

.label-checked {
    background-color: #A1CB5B;
    border: 1px solid #8BAF4E;
    color: #FFFFFF;
    cursor: pointer;
    margin-right: 2px;
}

.btn:hover, .btn:focus, .btn.focus {
    color: #FFFFFF;
    text-decoration: none;
}

/* transparent mouse over */
.tmo:hover, .tmo:focus, .tmo:active, .tmo.active, .open .dropdown-toggle.tmo, .tmo:active:focus, .tmo:active:hover, .tmo.active:hover, .tmo.active:focus
{
    opacity: 0.8;
    filter: alpha(opacity=100); /* For IE8 and earlier */
}

/* label action */
.la:hover, .la:focus {
    opacity: 0.8;
    filter: alpha(opacity=100); /* For IE8 and earlier */
    text-decoration: underline;
}

.student-files li a {
    font-size: 11px;
    margin-left: 10px;
    line-height: 22px;
}

.custom-wrapper-content {
    padding: 20px 0 70px;
}

.modal-open {
    overflow-y: auto
}

/*[data-balloon] {*/
    /*pointer-events: none;*/
/*}*/

/*.btn-docs:hover, .btn-docs:focus, .btn-docs:active, .btn-docs.active, .open .dropdown-toggle.btn-docs, .btn-docs:active:focus, .btn-docs:active:hover, .btn-docs.active:hover, .btn-docs.active:focus*/
/*{*/
    /*opacity: 0.8;*/
    /*filter: alpha(opacity=100); !* For IE8 and earlier *!*/
/*}*/

/*.btn-login-as:hover, .btn-login-as:focus, .btn-login-as:active, .btn-login-as.active, .open .dropdown-toggle.btn-login-as, .btn-login-as:active:focus, .btn-login-as:active:hover, .btn-login-as.active:hover, .btn-login-as.active:focus*/
/*{*/
    /*opacity: 0.8;*/
    /*filter: alpha(opacity=100); !* For IE8 and earlier *!*/
/*}*/